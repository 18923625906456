(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name components.directive:canvasDrawing
   * @restrict EA
   * @element
   *
   * @description
   *
   */
  angular
    .module('components')
    .directive('canvasDrawing', canvasDrawing);

  function canvasDrawing($rootScope) {
    return {
      restrict: 'A',
      scope: {
        color: '=',
        widthDrawing: '='
      },
      link: function (scope, element) {
        let ctx = element[0].getContext('2d'),
            // variable that decides if something should be drawn on mousemove
            drawing = false,
            untouched = true,
            // the last coordinates before the current move
            lastX,
            lastY,
            currentX,
            currentY;

        element.bind('mousedown', function (event) {
          if (angular.isDefined(event.offsetX)) {
            lastX = event.offsetX;
            lastY = event.offsetY;
          } else {
            // Firefox compatibility
            lastX = event.layerX - event.currentTarget.offsetLeft;
            lastY = event.layerY - event.currentTarget.offsetTop;
          }
          // begins new line
          ctx.beginPath();
          drawing = true;
          untouched = true;
        });
        element.bind('mousemove', function (event) {
          if (drawing) {
            if (untouched) {
              untouched = false;
              $rootScope.$emit('canvasTouched', true);
            }
            // get current mouse position
            if (angular.isDefined(event.offsetX)) {
              currentX = event.offsetX;
              currentY = event.offsetY;
            } else {
              currentX = event.layerX - event.currentTarget.offsetLeft;
              currentY = event.layerY - event.currentTarget.offsetTop;
            }
            draw(lastX, lastY, currentX, currentY);
            // set current coordinates to last one
            lastX = currentX;
            lastY = currentY;
          }
        });
        element.bind('mouseup', function () {
          // stop drawing
          drawing = false;
        });
        element.bind('touchstart', function (event) {
          drawMobile(event, true);
          event.preventDefault();
        });
        element.bind('touchmove', function (event) {
          drawMobile(event);
          event.preventDefault();
        });
        function drawMobile(event, start) {
          if (event.touches) {
            if (event.touches.length === 1) {
              let touch = event.touches[0];
              ctx.strokeStyle = scope.color;
              ctx.lineWidth = scope.widthDrawing || 1;
              if (start) {
                $rootScope.$emit('canvasTouched', true);
                ctx.beginPath();
                ctx.moveTo(getTouch(touch).x, getTouch(touch).y);
              } else {
                ctx.lineTo(getTouch(touch).x, getTouch(touch).y);
                ctx.stroke();
              }
            }
          }
        }
        function getTouch(e) {
          let target = e.target, offsetX = 0, offsetY = 0, mx, my;
          if (target.offsetParent) {
            do {
              offsetX += target.offsetLeft;
              offsetY += target.offsetTop;
            } while ((target = target.offsetParent) !== null);
          }
          mx = e.pageX - offsetX;
          my = e.pageY - offsetY;
          return {x: mx, y: my};
        }
        function draw(lX, lY, cX, cY) {
          // line from
          ctx.moveTo(lX, lY);
          // to
          ctx.lineTo(cX, cY);
          // color
          ctx.strokeStyle = scope.color;
          // Width
          ctx.lineWidth = scope.widthDrawing || 1;
          // draw it
          ctx.stroke();
        }
      }
    };
  }
}());
